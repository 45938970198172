<template>
	<div style="overflow-x: auto">
		<q-table
			v-if="media_files && media_files.length"
			hide-bottom
			binary-state-sort
			:columns="$q.screen.gt.xs ? columns : columns_smaller_screen"
			:data="media_files"
			class="border-left"
			:hide-header="is_message_forms ? true : false"
			:selection="is_message_forms ? 'multiple' : 'none'"
			:selected.sync="selected"
			:rows-per-page-options="[0]"
		>
			<template v-slot:body-cell-actions="props">
				<q-td :props="props">
					<MediaLibraryFile
						:media_file="props.row"
						:is_public_file="is_public_file"
					/>
				</q-td>
			</template>
		</q-table>
	</div>
</template>

<script>
	import MediaLibraryFile from '@/components/media_library/MediaLibraryFile.vue';
	import moment from 'moment-timezone'
	import { date } from 'quasar'
	import { mapGetters, mapActions } from "vuex";
	export default {
		components: {
			MediaLibraryFile,
		},
		name: "MediaLibraryTable",
		props:{
			media_files: Array,
			is_message_forms: Boolean,
			is_public_file: Boolean,
		},
		data(){
			return{
				columns: [
					{
						name: 'name',
						required: true,
						label: 'Name',
						align: 'left',
						field: row => row.name,
						format: val => `${val}`,
						sortable: true,
					},
				],
				columns_smaller_screen: [
					{
						name: 'name',
						required: true,
						label: 'Name',
						align: 'left',
						field: row => row.name,
						format: val => `${val}`,
						sortable: true,
					},
					{
						name: 'actions',
						label: 'Actions',
						align: 'center',
						style: 'text-align: right',
					},
				],
				selected: [],
				preferred_timezone: "",
			}
		},
		computed: {
			...mapGetters(['attachments', 'logged_user']),
		},
		methods: {
			...mapActions(['updateSelectedFile', 'updateSelectedFiles']),
			sendMediaFileToParent(selected_media_file){
				if(!this.attachments.includes(selected_media_file)){
					this.$store.dispatch('pushAttachmentsInArray', selected_media_file);
				}else{
					this.$store.dispatch('removeAttachmentFromArray', selected_media_file);
				}
				this.updateSelectedFiles(this.selected)
			},
		},
		created(){
			let additional_colums = [
				{
					name: 'created_at',
					field: obj => {
						return moment.utc(obj.created_at).tz(this.preferred_timezone).format('DD/MM/YYYY HH:mm')
					},
					label: 'Date Created',
					sortable: true,
					align: 'left',
				},
				{
					name: 'last_updated_at',
					field: obj => {
						return moment.utc(obj.last_updated_at).tz(this.preferred_timezone).format('DD/MM/YYYY HH:mm')
					},
					label: 'Date Updated',
					sortable: true,
					align: 'left',
				},
				{
					name: 'expiry_date',
					label: 'Expiry Date',
					sortable: true,
					field: obj => {
						return date.formatDate(
							obj.expiry_date, 'DD/MM/YYYY'
						)
					},
					align: 'left',
				},
				{
					name: 'review_date',
					label: 'Review Date',
					sortable: true,
					field: obj => {
						return date.formatDate(
							obj.review_date, 'DD/MM/YYYY'
						)
					},
					align: 'left',
				},
				{
					name: 'actions',
					label: 'Actions',
					align: 'center',
					style: 'text-align: right',
				},
			]
			let order_num = {
				name: 'order_number',
				label: 'Order No.',
				field: 'order_number',
				sortable: true,
				align: 'left',
			}
			let file_privacy = {
				name: 'file_privacy',
				label: 'File Privacy',
				field: 'file_privacy',
				sortable: true,
				align: 'left',
			}
			if (!this.is_message_forms){
				this.columns.unshift(order_num);
				this.columns = this.columns.concat(additional_colums)
				this.columns.splice(2, 0, file_privacy)
			}
			this.preferred_timezone = this.logged_user.preferred_timezone
			this.updateSelectedFile(this.selected);
			this.updateSelectedFiles(this.selected);
		},
		watch: {
			selected: function(){
				if (this.selected.length > 0){
					this.sendMediaFileToParent(this.selected[this.selected.length - 1])
				}
			},
		},
	};
</script>

<style lang="scss">
	.cg-ul {
		min-width: 550px;
		padding: 0;
	}

	.border-left {
		border-left: 1px solid $grey-4;
	}
</style>
